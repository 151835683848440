import { PureComponent } from "react";

import { connect } from "react-redux";
import styled from "styled-components";
import { showModal } from "@redux/slices/modalSlice";
import { Modals } from "components/modal/Modal.constants";

const Container = styled.span`
  color: ${(props) => props.theme.veryLightGrey};
  padding: 0.75em 1em;
  cursor: pointer;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "2em"};
  transition: ${(props) => props.theme.fastTransition};
  font-weight: bold;
  margin: 0.25em;
  background: ${(props) => props.theme.midBlue};
  &:hover {
    background: ${(props) => props.theme.midBlue};
    color: ${(props) => props.theme.white};
  }
  &:active {
    transform: scale(0.97);
  }
`;

// TODO - this component is never called with any props (?)

class SignUp extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.props.deactivated) {
      this.props.showModal({
        component: Modals.LOGIN_DISABLED,
        data: { company: this.props.company }
      })
    } else {
      this.props.showModal({
        component: Modals.LOGIN,
        data: { redirectPath: this.props.redirect || this.props.router.location.pathname },
      });
    }
  }

  render() {
    return (
      <Container
        onClick={this.handleClick}
        borderRadius={this.props.borderRadius}
      >
        {this.props.text ? this.props.text : "Sign Up"}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  router: state.router,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: (payload) => dispatch(showModal(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
