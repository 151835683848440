import { connect } from "react-redux";
import { PureComponent } from "react";
import { LoginOption } from "constants/styled-components/index";
import { awsPrefix } from "constants/variables/index";
import styled from "styled-components";
import { generateRandomString } from "constants/functions/index";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
const OrSection = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;
const Divider = styled.div`
  background: ${(props) => props.theme.grey5};
  height: 1px;
  width: 100%;
`;
const Or = styled.h3`
  font-weight: bold;
  font-size: 0.8em;
  margin: 1.5em;
  color: ${(props) => props.theme.grey3};
`;

class Sso extends PureComponent {
  constructor(props) {
    super(props);
    this.loginWithMicrosoft = this.loginWithMicrosoft.bind(this);
    this.loginWithGoogle = this.loginWithGoogle.bind(this);
  }
  loginWithMicrosoft() {
    const clientId = "b010e464-6d9e-45ac-85e5-59b4bdb7f390";
    const redirectUri = `${window.location.origin}/callback/microsoft`;
    const state = generateRandomString();
    localStorage.setItem("localAuthState", state);
    localStorage.setItem("localAuthMethod", "microsoft");
    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=token&scope=user.read&state=${state}`;
  }
  loginWithGoogle() {
    const clientId =
      "264446693729-kte0ort4mkn496k1op058ebsaelu76es.apps.googleusercontent.com";
    const redirectUri = `${window.location.origin}/callback/google`;
    const state = generateRandomString();
    localStorage.setItem("localAuthState", state);
    localStorage.setItem("localAuthMethod", "google");
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=token&scope=openid%20email%20profile&state=${state}`;
  }
  render() {
    return (
      <Wrapper>
        <OrSection>
          <Divider />
          <Or>OR</Or>
          <Divider />
        </OrSection>
        <LoginOption onClick={this.loginWithMicrosoft}>
          <img src={`${awsPrefix}web-app/microsoft.svg`} />
          Continue with Microsoft Account
        </LoginOption>
        <LoginOption onClick={this.loginWithGoogle}>
          <img src={`${awsPrefix}web-app/google.svg`} />
          Continue with Google Workspace
        </LoginOption>
      </Wrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
});

export default connect(null, mapDispatchToProps)(Sso);
