import "react-phone-input-2/lib/style.css";
import { FaAndroid, FaApple, FaQrcode, FaSms } from "react-icons/fa";
import { PureComponent } from "react";
import {
  androidAppUrl,
  appleAppUrl,
  awsPrefix,
} from "constants/variables/index";
import AltCTA from "components/buttons/alternate/index";
import Loader from "components/loader/index";
import MainCTA from "components/buttons/main/index";
import QRVideoComponent from "components/qr-code/index";
import PhoneInput from "react-phone-input-2";
import Tooltip from "components/tooltip/index";
import { TooltipText } from "constants/styled-components/index";
import { connect } from "react-redux";
import { media } from "constants/responsive/index";
import styled from "styled-components";

const Wrapper = styled.div`
  text-align: center;
  margin-left: 0.5em;
  margin-right: 0.5em;
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1em;
`;
const Container = styled.div`
  width: 100%;
  animation: fadeIn 1s;
  display: flex;
  align-items: center;
  justify-content: center;
  .react-tel-input {
    width: auto;
  }
  ${media.mobile`
    flex-wrap: wrap;
  `}
`;
const P = styled.p`
  color: ${(props) => props.theme.grey2};
  padding: 1em;
  animation: fadeIn 1s;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
`;
const A = styled.a`
  margin: 0.5em;
  padding: 1em;
  border-radius: 50%;
  box-shadow: ${(props) => props.theme.subtleBoxShadow};
  background: ${(props) => props.theme.white};
  svg {
    transition: ${(props) => props.theme.defaultTransition};
    fill: ${(props) => props.theme.grey2};
  }
  svg:hover {
    fill: ${(props) => props.theme.grey3};
    transform: scale(1.1);
  }
`;
const Divider = styled.div`
  width: 100%;
  padding: 0.25em;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.mobile`
    flex-wrap: wrap;
  `}
`;

class MobileDownload extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      phone: null,
      valid: false,
      showQrCode: false,
      showSms: false,
      sendAppToPhone: false,
    };
    this.showQRCode = this.showQRCode.bind(this);
    this.showSms = this.showSms.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
    this.tryAgain = this.tryAgain.bind(this);
    this.sendAppToPhone = this.sendAppToPhone.bind(this);
    this.isValidPhoneNumber = this.isValidPhoneNumber.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
  }
  handlePhoneChange(phone) {
    this.setState({ phone });
  }
  isValidPhoneNumber(inputNumber, country, countries) {
    const isValidDialCode = countries.some((c) => {
      return (
        inputNumber.startsWith(c.dialCode) || c.dialCode.startsWith(inputNumber)
      );
    });
    const requiredLength = (country.format.match(/\./g) || []).length;
    const inputLength = inputNumber.replace(/\D/g, "").length;
    this.setState({ valid: isValidDialCode && inputLength >= requiredLength });
  }
  showQRCode() {
    this.setState({
      showQrCode: true,
    });
    if (this.props.qrShow) {
      this.props.qrShow();
    }
  }

  showSms() {
    this.setState({
      showSms: true,
    });
  }

  hideMenu() {
    this.setState({
      showSms: false,
      showQrCode: false,
    });
    if (this.props.qrHide) {
      this.props.qrHide();
    }
  }

  sendAppToPhone() {
    this.props.apiCall({
      method: "GET",
      component: "SEND_APP_TO_PHONE",
      route: `sms/mobile_download/${this.state.phone}`,
    });
    this.setState({
      sendAppToPhone: true,
    });
  }

  tryAgain() {
    this.setState({
      sendAppToPhone: false,
    });
  }


  render() {
    return (
      <Wrapper>
        {this.state.showSms ? (
          <Flex>
            <Divider
              style={{
                flexWrap: "wrap",
              }}
            >
              {this.props.fetchReducer &&
              this.props.fetchReducer.fetching &&
              this.props.fetchReducer.component == "SEND_APP_TO_PHONE" ? (
                <Loader />
              ) : this.state.sendAppToPhone && this.state.phone ? (
                <>
                  <Container>
                    <P>{`We just sent a download link to ${this.state.phone}`}</P>
                  </Container>
                  <Container>
                    <P>Didn't receive an SMS?</P>
                    <P
                      style={{ fontWeight: "bold", cursor: "pointer" }}
                      onClick={this.tryAgain}
                    >
                      Try Again
                    </P>
                  </Container>
                </>
              ) : (
                <>
                  {this.props.text ? (
                    <Container>
                      <P>{this.props.text}</P>
                    </Container>
                  ) : null}
                  <Container id="phone-hoc">
                    <PhoneInput
                      country="us"
                      value={this.state.phone}
                      isValid={this.isValidPhoneNumber}
                      onChange={this.handlePhoneChange}
                      disableDropdown
                    />
                  </Container>
                </>
              )}
            </Divider>
            <Divider>
              <MainCTA
                text="Send app to my phone"
                blue
                disabled={
                  !this.state.valid ||
                  !this.state.phone ||
                  (this.state.phone && this.state.phone.length < 1)
                }
                clickAction={this.sendAppToPhone}
              />
              <AltCTA text="Back" clickAction={this.hideMenu} />
            </Divider>
          </Flex>
        ) : this.state.showQrCode ? (
          <Flex>
            <Divider>
              <QRVideoComponent
                size={200}
                value="https://mobiledownload.shelfgram.com/"
              />
            </Divider>
            <Divider>
              <AltCTA text="Close QR code" clickAction={this.hideMenu} />
            </Divider>
          </Flex>
        ) : (
          <>
            <P>Download the Shelfgram mobile app</P>
            <Flex>
              <Tooltip
                unmountHTMLWhenHide
                html={<TooltipText>Send app to my phone</TooltipText>}
                position="top"
                width="auto"
              >
                <A onClick={this.showSms}>
                  <FaSms size="2em" />
                </A>
              </Tooltip>
              <Tooltip
                unmountHTMLWhenHide
                html={<TooltipText>Open the App Store</TooltipText>}
                position="top"
                width="auto"
              >
                <A
                  href={appleAppUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FaApple size="2em" />
                </A>
              </Tooltip>
              <Tooltip
                unmountHTMLWhenHide
                html={<TooltipText>Open the Google Play store</TooltipText>}
                position="top"
                width="auto"
              >
                <A
                  href={androidAppUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FaAndroid size="2em" />
                </A>
              </Tooltip>
              <Tooltip
                unmountHTMLWhenHide
                html={<TooltipText>Show a QR code</TooltipText>}
                position="top"
                width="auto"
              >
                <A onClick={this.showQRCode}>
                  <FaQrcode size="2em" />
                </A>
              </Tooltip>
            </Flex>
          </>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  fetchReducer: state.fetchReducer,
});

const mapDispatchToProps = (dispatch) => ({
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileDownload);
