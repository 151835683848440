import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from "react";
import ErrorMessage from "components/auth-options/error-message";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useApiCallStatus } from "hooks/useApiCallStatus";
import NoAccess from "components/no-access";
import Loader from "components/loader";
import { checkAccessToCollection } from "@redux/api/endpoints/collections";
import { selectIsFreePlanUser } from "@redux/selectors/user";
export var AccessHoc = function (_a) {
    var children = _a.children, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var isFreePlanUser = useAppSelector(selectIsFreePlanUser);
    var collectionId = useAppSelector(function (state) { var _a; return (_a = state.dataLandingPageAbout) === null || _a === void 0 ? void 0 : _a.id; });
    var hasAccess = useAppSelector(function (state) { var _a; return !!((_a = state.dataLandingPageAbout) === null || _a === void 0 ? void 0 : _a.access); });
    var checkedAccess = useAppSelector(function (state) { var _a; return (_a = state.dataLandingPageAbout) === null || _a === void 0 ? void 0 : _a.access_check; });
    var isError = useApiCallStatus(checkAccessToCollection).isError;
    var dispatch = useAppDispatch();
    useEffect(function () {
        if (!disabled && collectionId && !checkedAccess) {
            dispatch(checkAccessToCollection({
                routeParams: { collectionId: collectionId }
            }));
        }
    }, [disabled, collectionId, checkedAccess]);
    return disabled || hasAccess ? (children) : isFreePlanUser || checkedAccess && !hasAccess ? (_jsx(NoAccess, {})) : isError ? (_jsx(ErrorMessage, { error: "An error occurred, please try again" })) : (_jsx(Loader, {}));
};
