import { PureComponent } from "react";
import { awsPrefix } from "constants/variables";
import { avatarFromName } from "shared/helpers/image";
import AltCTA from "components/buttons/alternate";
import Loader from "components/loader";
import MainCTA from "components/buttons/main";
import { TextInput } from "constants/styled-components";
import { connect } from "react-redux";
import isURL from "validator/lib/isURL";
import styled from "styled-components";
import { hideModal } from "@redux/slices/modalSlice";
import { createNewCompany } from "@redux/api/endpoints/companies";
import { addTagsToFlyer } from "@redux/api/endpoints/flyers";
import { addTagsToPhoto } from "@redux/api/endpoints/photos";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.grey6};
  width: 100%;
`;
const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
const P = styled.p`
  margin: auto;
  width: 100%;
  color: ${(props) => props.theme.grey2};
  text-align: left;
  font-weight: bold;
  padding: 1em 0;
  box-sizing: border-box;
`;
const Form = styled.form`
  margin: auto;
  width: 100%;
  padding: 1em 3em;
  box-sizing: border-box;
`;
const Adding = styled.div`
  padding: 2em;
`;
const CreatedCompany = styled.div`
  width: 13em;
  height: 7em;
  align-items: center;
  justify-content: center;
  transition: ${(props) => props.theme.defaultTransition};
  padding: 1em;
  flex-wrap: nowrap;
  background: ${(props) =>
    props.primaryColor && props.secondaryColor
      ? `linear-gradient(rgb${props.primaryColor}, rgb${props.secondaryColor})`
      : props.theme.grey4};
  border-radius: 0.5em;
  background-position: center;
  background-size: cover;
  position: relative;
  margin: 2em;
  box-shadow: ${(props) => props.theme.sortOfSubtleBoxShadow};
  cursor: pointer;
  display: flex;
  border: 2px solid ${(props) => props.theme.white};
  overflow: hidden;
  &:hover {
    transform: scale(1.02);
  }
`;
const CompanyCircle = styled.div`
  border-radius: 1em;
  box-shadow: ${(props) => props.theme.sortOfSubtleBoxShadow};
  padding: 1em;
  margin: 1em;
  background: ${(props) => props.theme.grey4};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CompanyIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  padding: 2em;
  background: ${(props) => props.theme.white};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;
const Company = styled.p`
  padding: 0.5em;
  margin: 0;
  font-weight: bold;
  color: ${(props) => props.theme.white};
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  text-rendering: optimizelegibility;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
`;

class CreateCompany extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      url: "",
      validated: false,
      submitted: false,
    };
    this.handleCompanySelect = this.handleCompanySelect.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleUrlChange = this.handleUrlChange.bind(this);
    this.handleCompanyCreate = this.handleCompanyCreate.bind(this);
    this.handleAddTagToPhoto = this.handleAddTagToPhoto.bind(this);
  }

  componentDidMount() {
    const name = this.props.name || this.props.defaultName;
    if (name) {
      this.setState({ name })
    }
  }

  handleAddTagToPhoto() {
    const { dataViewer, createdCompany, isFlyer } = this.props;
    if (dataViewer?.id) {
      const { id: url_slug, img, name, primary_color } = createdCompany;
      const body = {
        photo_id: dataViewer.id,
        type: "companies",
        img: img || `${awsPrefix}companies/generic_company.png`,
        primary_color,
        url_slug,
        name,
      }
      this.props.dispatch(isFlyer
        ? addTagsToFlyer({ body })
        : addTagsToPhoto({ body })
      );
    }
    this.props.hideModal();
  }

  handleCompanySelect() {
    if (this.props.handleCompanySelect) {
      this.props.handleCompanySelect(this.props.createdCompany);
    }
  }
  handleCompanyCreate() {
    this.props.dispatch(
      createNewCompany({
        body: {
          company_name: this.state.name,
          company_website: this.state.url,
          is_brand: this.props.companyType === "brand",
          is_retailer: this.props.companyType === "retailer"
        }
      })
    );
    this.setState({
      submitted: true,
    });
  }
  handleNameChange(e) {
    const val = e.target.value.replace("/", "-");
    this.setState({
      name: val,
    });
    this.checkValidation(this.state.url, val);
  }
  handleUrlChange(e) {
    this.setState({
      url: e.target.value,
    });
    this.checkValidation(e.target.value, this.state.name);
  }
  checkValidation(url, name) {
    if (isURL(url) && name.length > 2) {
      this.setState({
        validated: true,
      });
    } else {
      this.setState({
        validated: false,
      });
    }
  }
  render() {
    return (
      <Wrapper>
        {this.props.createdCompany ? (
          this.props.showTagging ? (
            <Wrapper>
              <CompanyCircle>
                <CompanyIcon
                  style={{
                    marginRight: "1em",
                    borderRadius: "1em",
                    backgroundImage: this.props.createdCompany.img
                      ? `url("${awsPrefix}companies/${this.props.createdCompany.img}")`
                      : `url("${avatarFromName(this.props.createdCompany.name)}")`,
                  }}
                />
                <P>
                  Successfully added {this.props.createdCompany.name} to
                  Shelfgram
                </P>
              </CompanyCircle>
              <ButtonContainer>
                <AltCTA
                  text="Close"
                  clickAction={this.props.hideModal}
                />
                {this.props.dataViewer && this.props.dataViewer.id ? (
                  <MainCTA
                    text={`Add ${this.props.createdCompany.name} to this ${
                      this.props.isFlyer
                        ? "flyer"
                        : "photo"
                    }`}
                    clickAction={this.handleAddTagToPhoto}
                  />
                ) : null}
              </ButtonContainer>
            </Wrapper>
          ) : (
            <CreatedCompany
              onClick={this.handleCompanySelect}
              primaryColor={this.props.createdCompany.primary_color}
              secondaryColor={this.props.createdCompany.secondary_color}
            >
              <CompanyIcon
                style={{
                  backgroundImage: this.props.createdCompany.img
                    ? `url("${awsPrefix}companies/${this.props.createdCompany.img}")`
                    : `url("${avatarFromName(this.props.createdCompany.name)}")`,
                }}
              />
              <Company>{this.props.createdCompany.name}</Company>
            </CreatedCompany>
          )
        ) : this.state.submitted ? (
          <Adding>
            <P>{`Adding ${this.state.name} to Shelfgram...`}</P>
            <Loader />
          </Adding>
        ) : (
          <Form>
            <P>{`Tell us the name of the ${
              this.props.companyType || "company"
            } you'd like to add`}</P>
            <TextInput
              type="text"
              maxLength={60}
              placeholder={
                this.props.companyType === "brand"
                  ? "Brand name"
                  : this.props.companyType === "retailer"
                    ? "Retailer name"
                    : "Company name"
              }
              value={this.state.name || ""}
              onChange={this.handleNameChange}
            />
            <P>{`Tell us website URL for ${
              this.state.name
            } to make sure we're adding the right ${
              this.props.companyType || "company"
            }`}</P>
            <TextInput
              type="text"
              placeholder={`Website URL for ${
                this.state.name && this.state.name.length > 1
                  ? this.state.name
                  : `this ${this.props.companyType || "company"}`
              }`}
              value={this.state.url || ""}
              onChange={this.handleUrlChange}
            />
            <MainCTA
              text="Create company"
              type="button"
              disabled={!this.state.validated}
              clickAction={
                this.state.validated ? this.handleCompanyCreate : null
              }
            />
          </Form>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  showTagging: state.showTagging,
  dataUser: state.dataUser,
  createdCompany: state.createdCompany,
  dataViewer: state.dataViewer,
});

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) => dispatch({ type: arg.type, data: arg.data }),
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
  hideModal: () => dispatch(hideModal()),
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCompany);
