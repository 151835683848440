import { jsx as _jsx } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import React, { useEffect, useState } from "react";
import { TooltipText, LandingPageProfilePic } from "constants/styled-components";
import { fetchInitialLoad } from "@redux/api/endpoints/search";
import Tooltip from "components/tooltip";
export var ChooseProfilePicture = function (_a) {
    var currentImg = _a.currentImg, onSelect = _a.onSelect;
    var initialLoadCategories = useAppSelector(function (state) { return state.initialLoad.categories; });
    var _b = useState(currentImg), selectedImg = _b[0], setSelectedImg = _b[1];
    var dispatch = useAppDispatch();
    useEffect(function () {
        if (!initialLoadCategories.length) {
            dispatch(fetchInitialLoad());
        }
    }, []);
    var handleProfilePicSelect = function (img, source) {
        setSelectedImg(img);
        onSelect(img, source);
    };
    return !!initialLoadCategories.length && (initialLoadCategories.map(function (_a) {
        var id = _a.id, name = _a.name, img = _a.img, source = _a.source;
        return (_jsx(Tooltip, { position: "top", width: "auto", html: _jsx(TooltipText, { children: name }), unmountHTMLWhenHide: true, children: _jsx(LandingPageProfilePic, { onClick: function () { return handleProfilePicSelect(img, source); }, selected: img === selectedImg, source: source, img: img, tabIndex: 0 }) }, id));
    }));
};
