import { PureComponent } from "react";
import styled, { withTheme } from "styled-components";

import Card from "components/feed/cards/index";
import DocumentTitle from "react-document-title";
import { FaThumbsDown } from "react-icons/fa";
import Loader from "components/loader/index";
import JoinToast from "components/toasts/join/index";
import { queryStringToObject } from "constants/functions";
import NothingYet from "components/nothing-yet/index";
import SimpleSkeletonCard from "components/simple-skeleton-card/index";
import { connect } from "react-redux";
import isUUID from "validator/lib/isUUID";
import { withRouter } from "services/with-router/index";
import { media } from "constants/responsive/index";
import toast from "react-hot-toast";
import { showModal } from "@redux/slices/modalSlice";
import { fetchFlyerByImg } from "@redux/api/endpoints/flyers";
import { fetchPhotoByImg } from "@redux/api/endpoints/photos";

const CARD_WIDTH = 380;

const Container = styled.div`
  width: 100%;
  padding-bottom: 4em;
  height: 100%;
  align-items: flex-start;
  padding-top: 2em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${media.mobile`
    padding-bottom: 2em;
  `};
`;
const CardContainer = styled.div`
  width: ${CARD_WIDTH}px;
  max-width: 90vw;
  padding: 0.75em 0.75em 2em 0.75em;
  ${media.mobile`
    padding-top: 0;
  `};
`;

class SharePage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isUUID: true,
      showToast: false,
    };
    this.handleViewerOpen = this.handleViewerOpen.bind(this);
  }

  componentDidMount() {
    this.setSharePage(this.props.params.shareId);
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.dataUser &&
      !prevProps.dataSharePage &&
      this.props.dataSharePage &&
      !this.props.showViewer
    ) {
      this.props.setData({
        type: "SHOW_VIEWER",
        data: {
          type: this.props.isFlyer ? "FLYER" : "SHARE_PAGE",
        },
      });
    } else if (prevProps.params.shareId !== this.props.params.shareId) {
      this.setSharePage(this.props.params.shareId);
    } else if (
      !this.props.dataUser &&
      prevProps.showViewer &&
      !this.props.showViewer &&
      !this.state.showToast
    ) {
      this.setState({ showToast: true });
      setTimeout(() => {
        toast(<JoinToast />, { duration: 90000000 });
      }, 1000);
    }
  }

  handleViewerOpen() {
    this.props.setData({
      type: "VIEWER_DATA_SET",
      data: this.props.dataSharePage,
    });
    this.props.setData({
      type: "SHOW_VIEWER",
      data: { type: this.props.isFlyer ? "FLYER" : "SHARE_PAGE" },
    });
  }

  setSharePage(shareId) {
    const subString = window.location.pathname.substring(0, 3);
    if (["/s/", "/f/"].includes(subString)) {
      if (isUUID(shareId)) {
        const routeParams = {
          img: shareId
        };
        this.props.dispatch(
          subString === "/f/"
            ? fetchFlyerByImg({ routeParams })
            : fetchPhotoByImg({ routeParams })
        );
      } else {
        this.setState({
          isUUID: false,
        });
      }
    }
    const queryObjects = queryStringToObject(this.props.router.location.search);
    if (queryObjects && queryObjects.viewerOpen) {
      this.handleViewerOpen();
    }
  }

  render() {
    return (
      <DocumentTitle title="Shared Photo - Shelfgram">
        <Container>
          {this.props.isFetching || (this.state.isUUID && !this.props.dataSharePage) ? (
            <SimpleSkeletonCard pills={1} width="50vw" height="80vh" />
          ) : !this.state.isUUID ? (
            <NothingYet
              iconComponent={<FaThumbsDown
                size="2em"
                color={this.props.theme.deepRed}
              />}
              noCta
              bodyText="The link you're using looks to be invalid. Double check and try again!"
            />
          ) : this.props.dataSharePage ? (
            this.props.dataSharePage.error ? (
              <NothingYet
                iconComponent={<FaThumbsDown
                  size="2em"
                  color={this.props.theme.deepRed}
                />}
                noCta
                bodyText="This photo does not exist or has been removed"
              />
            ) : (
              <CardContainer>
                <Card
                  fullHeight
                  showCity
                  showCountry
                  showStoreNumber
                  isSharePage
                  headerWidth={CARD_WIDTH}
                  isFlyer={this.props.isFlyer}
                  data={this.props.dataSharePage}
                  handleViewerOpen={this.handleViewerOpen}
                />
              </CardContainer>
            )
          ) : null}
        </Container>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = (state) => ({
  dataSharePage: state.dataSharePage,
  dataUser: state.dataUser,
  isFetching: state.apiCalls["FETCH_PHOTO"]?.fetching || state.apiCalls["FETCH_FLYER"]?.fetching,
  showViewer: state.showViewer,
  router: state.router,
});

const mapDispatchToProps = (dispatch) => ({
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
  setData: (arg) =>
    dispatch({ type: arg.type, data: arg.data, extraData: arg.extraData }),
  showModal: (payload) => dispatch(showModal(payload)),
  dispatch
});

export default withRouter(
  withTheme(connect(mapStateToProps, mapDispatchToProps)(SharePage))
);
