import { PureComponent } from "react";

import { connect } from "react-redux";
import styled from "styled-components";
import { showModal } from "@redux/slices/modalSlice";
import { Modals } from "components/modal/Modal.constants";

const Container = styled.span`
  color: ${(props) => props.theme.grey2};
  padding: 0.75em 1em;
  cursor: pointer;
  border-radius: 2em;
  transition: ${(props) => props.theme.fastTransition};
  font-weight: bold;
  margin: 0.25em;
  background: ${(props) => props.theme.grey5};
  &:hover {
    background: ${(props) => props.theme.grey6};
    color: ${(props) => props.theme.grey1};
  }
  &:active {
    transform: scale(0.97);
  }
`;

class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.showModal({
      component: Modals.LOGIN,
      data: { redirectPath: this.props.router.location.pathname }
    });
  }

  render() {
    return <Container onClick={this.handleClick}>Login</Container>;
  }
}

const mapStateToProps = (state) => ({
  router: state.router,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: (payload) => dispatch(showModal(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
