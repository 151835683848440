var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AdvancedFeedFiltersToggle } from "screens/user/settings/advanced-feed-filters/AdvancedFeedFiltersToggle";
import { MODAL_FULL_SCREEN_BREAKPOINTS } from "components/modal/Modal.constants";
import styled, { useTheme } from "styled-components";
import { Flex } from "constants/styled-components";
import AltCTA from "components/buttons/alternate";
import MainCTA from "components/buttons/main";
import { hideModal } from "@redux/slices/modalSlice";
import { useMediaQuery } from "react-responsive";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { toggleAdvancedFilters } from "@redux/api/endpoints/users";
import { selectUserData } from "@redux/selectors/user";
import { useState } from "react";
var Wrapper = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-wrap: wrap;\n  align-items: flex-start;\n  transition: ", ";\n  animation: fadeIn 1s;\n  margin: 1.25em 1.5em;\n  overflow: hidden;\n\n  @media (max-width: ", "px) {\n    margin-bottom: 3em;\n  };\n"], ["\n  flex-wrap: wrap;\n  align-items: flex-start;\n  transition: ", ";\n  animation: fadeIn 1s;\n  margin: 1.25em 1.5em;\n  overflow: hidden;\n\n  @media (max-width: ", "px) {\n    margin-bottom: 3em;\n  };\n"])), function (_a) {
    var theme = _a.theme;
    return theme.defaultTransition;
}, MODAL_FULL_SCREEN_BREAKPOINTS.FEED_FILTERS);
var SettingsContainer = styled(Flex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  justify-content: space-between;\n  align-items: flex-start;\n  gap: 5em;\n"], ["\n  justify-content: space-between;\n  align-items: flex-start;\n  gap: 5em;\n"])));
var Setting = styled(Flex)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  justify-content: flex-start;\n  flex-direction: column;\n\n  h3 {\n    width: 100%;\n    font-weight: 700;\n    margin: 0.5em auto;\n  }\n\n  p {\n    margin: 0;\n    font-size: 0.9em;\n    width: 100%;\n  }\n"], ["\n  color: ", ";\n  justify-content: flex-start;\n  flex-direction: column;\n\n  h3 {\n    width: 100%;\n    font-weight: 700;\n    margin: 0.5em auto;\n  }\n\n  p {\n    margin: 0;\n    font-size: 0.9em;\n    width: 100%;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.grey2;
});
var ButtonsContainer = styled(Flex)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  gap: 1em;\n  justify-content: space-between;\n  margin-top: 6.5em;\n  padding-top: 1em;\n  margin-right: 0.5em;\n  border-top: 1px solid ", ";\n\n  @media (max-width: ", "px) {\n    justify-content: center;\n  }\n"], ["\n  gap: 1em;\n  justify-content: space-between;\n  margin-top: 6.5em;\n  padding-top: 1em;\n  margin-right: 0.5em;\n  border-top: 1px solid ", ";\n\n  @media (max-width: ", "px) {\n    justify-content: center;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.grey5;
}, MODAL_FULL_SCREEN_BREAKPOINTS.FEED_FILTERS);
export var FeedFilterSettings = function (_a) {
    var onSave = _a.onSave, onClose = _a.onClose;
    var isFullscreen = useMediaQuery({ maxWidth: MODAL_FULL_SCREEN_BREAKPOINTS.FEED_FILTERS });
    var initialUseAdvancedFilters = useAppSelector(function (state) { var _a; return !!((_a = selectUserData(state)) === null || _a === void 0 ? void 0 : _a.use_advanced_filters); });
    var _b = useState(initialUseAdvancedFilters), useAdvancedFilters = _b[0], setUseAdvancedFilters = _b[1];
    var dispatch = useAppDispatch();
    var theme = useTheme();
    var handleToggle = function (toggledOn) {
        setUseAdvancedFilters(toggledOn);
    };
    var handleClose = function () {
        if (typeof onClose === "function") {
            onClose();
        }
        else {
            dispatch(hideModal());
        }
    };
    var handleSave = function () {
        if (useAdvancedFilters !== initialUseAdvancedFilters) {
            dispatch(toggleAdvancedFilters({
                body: { active: useAdvancedFilters }
            }));
        }
        if (typeof onSave === "function") {
            onSave(useAdvancedFilters);
        }
        handleClose();
    };
    return (_jsx(Flex, { children: _jsxs(Wrapper, { children: [_jsxs(SettingsContainer, { children: [_jsxs(Setting, { children: [_jsx("h3", { children: "Filter groups" }), _jsxs("p", { children: ["Enables multiple sets of filters to see results that match the criteria of any group.", _jsx("br", {}), "For example, you can search for displays across multiple seasonal events", _jsx("br", {}), "(Tag is all of Christmas + Displays ", _jsx("strong", { children: _jsx("i", { children: "OR" }) }), " Tag is all of Halloween + Displays)"] })] }), _jsx(AdvancedFeedFiltersToggle, { onToggle: handleToggle, noPadding: true })] }), _jsxs(ButtonsContainer, { children: [!isFullscreen && (_jsx(Flex, {})), _jsx(AltCTA, { width: "10em", text: "Cancel", clickAction: handleClose, color: theme.grey2, colorHover: theme.midRed, background: theme.white, backgroundHover: theme.white, border: "1px solid ".concat(theme.grey5) }), _jsx(MainCTA, { noMargin: true, text: "Save", width: "18em", clickAction: handleSave })] })] }) }));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
