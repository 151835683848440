import { PureComponent } from "react";
import {
  awsPrefix,
  companyWebsite,
  loginCookieName,
  copyright,
} from "constants/variables/index";
import {
  generateRandomString,
  removeQueryParamsFromUrl,
  setCookie,
  queryStringToObject,
} from "constants/functions/index";
import { LoginOption } from "constants/styled-components/index";
import styled, { withTheme } from "styled-components";
import Sso from "./sso/index";
import AltCTA from "components/buttons/alternate/index";
import MainCTA from "components/buttons/main/index";
import ErrorMessage from "./error-message/index";
import { FaEnvelope, FaAngleLeft } from "react-icons/fa";
import Loader from "components/loader/index";
import ReactCodeInput from "react-code-input";
import { connect } from "react-redux";
import isEmail from "validator/lib/isEmail";
import { withRouter } from "services/with-router/index";
import Logo from "components/logo/index";

const External = styled.a`
  font-weight: bold;
`;

const Form = styled.form`
  width: 100%;
  max-width: 26em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.5em;
  margin: 0;
`;

const Spacer = styled.div`
  margin: 0.5em 0;
`;

const SubRightContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Input = styled.input`
  border-radius: 0.25em;
  width: 100%;
  padding: 1em;
  margin-left: 0.25em;
  margin-right: 0.25em;
  margin-bottom: 1em;
  border-width: 1px;
  border-styled: solid;
  transition: ${(props) => props.theme.fastTransition};
  border-color: ${(props) =>
    props.error
      ? props.theme.midRed
      : props.focused
      ? props.theme.grey2
      : props.theme.grey4};
  &::placeholder {
    color: ${(props) => props.theme.grey4};
  }
`;

const SubContainer = styled.div`
  width: 100%;
  color: ${(props) => props.theme.grey2};
  padding: 0.5em;
  animation: fadeIn 1s;
  transition: ${(props) => props.theme.defaultTransition};
  justify-content: ${(props) => (props.left ? "left" : "center")};
  font-size: ${(props) => (props.smallText ? "0.8em" : "1em")};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  p {
    margin: 0;
    text-align: center;
    width: 90%;
    text-wrap: balance;
  }
`;

const CODE_LENGTH = 4;
const BLACKLISTED_TLDS = [
  "hotmail.",
  "icloud.",
  "sina.",
  "gmail.",
  "msn.com",
  "live.ca",
  "live.com",
  "yahoo.",
  "outlook.",
  "aol.com",
  "comcast.",
];

const defaultState = {
  error: {},
  verificationCode: "",
  ssoEmail: "",
  loginWithSso: false,
  checked_enterprise: false,
};

const styles = {
  ssoEmail: {
    fontWeight: "bold",
    padding: "5px",
  },
  subContainer: {
    marginBottom: "1em",
    textAlign: "center",
  },
  spacer: {
    height: "2em",
  },
  terms: {
    marginTop: "1.5em",
    opacity: 0.8,
  },
};

const errorMapping = {
  no_hd: {
    sso: true,
    error: {
      tooltip:
        "You can always login to our free plan using your LinkedIn account.",
      message:
        "Sorry, but our Google login only works with Workspace account from approved domains and not personal Gmail accounts",
    },
  },
};

class AuthOptions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = defaultState;
    this.loginWithLinkedIn = this.loginWithLinkedIn.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleVerificationCodeChange =
      this.handleVerificationCodeChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSsoBack = this.handleSsoBack.bind(this);
    this.handleSsoClick = this.handleSsoClick.bind(this);
    this.handleEmailAddressChange = this.handleEmailAddressChange.bind(this);
    this.handleSsoSubmit = this.handleSsoSubmit.bind(this);
    this.hideUserLogo = this.hideUserLogo.bind(this);
    this.showUserLogo = this.showUserLogo.bind(this);
  }

  componentDidMount() {
    const error = queryStringToObject(this.props.router.location.search).error;
    localStorage.setItem("localAuthMethod", null);
    localStorage.setItem(
      "redirectPath",
      this.props.redirectPath ||
        this.props.router.location.pathname + this.props.router.location.search
    );
    const onlyWork =
      queryStringToObject(this.props.router.location.search).work ||
      this.props.router.location.pathname.includes("/e/");
    this.setState({
      mounted: true,
      onlyWork: onlyWork,
      error:
        error && errorMapping[error] && errorMapping[error].error
          ? errorMapping[error].error
          : {},
      loginWithSso: onlyWork
        ? true
        : error && errorMapping[error] && errorMapping[error].sso
        ? true
        : false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.verificationCode.length === CODE_LENGTH &&
      prevState.verificationCode !== this.state.verificationCode
    ) {
      this.props.apiCall({
        method: "POST",
        component: "USER_LOGS_IN",
        body: {
          client_id: 1,
          login_option_id: 4,
          username: this.state.ssoEmail,
          authorization: this.state.verificationCode,
          profile: {
            email: this.state.ssoEmail,
          },
        },
        route: "user/shelf_token",
      });
    }

    if (
      this.props.checkEnterpriseTld &&
      this.props.checkEnterpriseTld.redirect
    ) {
      window.location.href = this.props.checkEnterpriseTld.redirect;
    }

    if (
      !prevProps.dataUser &&
      this.props.dataUser &&
      this.props.dataUser.admin_impersonator_id
    ) {
      setCookie(loginCookieName, this.props.dataUser.shelf_token);
    }
  }

  loginWithLinkedIn() {
    const clientId = "78vz04942j0qy1";
    const redirectUri = `${window.location.origin}/callback/linkedin`;
    const state = generateRandomString();
    localStorage.setItem("localAuthState", state);
    localStorage.setItem("localAuthMethod", "linkedIn");
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=r_liteprofile%20r_emailaddress&state=${state}`;
  }

  showUserLogo() {
    this.setState({
      showUserLogo: true,
    });
  }

  hideUserLogo() {
    this.setState({
      removeLogo: true,
    });
  }

  handleFocus() {
    this.setState({ isFocused: true });
  }

  handleBlur() {
    this.setState({ isFocused: false });
  }

  handleVerificationCodeChange(x) {
    this.setState({
      verificationCode: x,
    });
  }

  handleSsoBack() {
    this.props.setData({ type: "RESET_CHECK_ENTERPRISE_TLD" });
    removeQueryParamsFromUrl();
    this.setState(defaultState);
  }

  handleSsoClick() {
    this.setState({ loginWithSso: true });
  }

  handleKeyPress(event) {
    if (
      event.which === 13 &&
      ((this.state.ssoEmail &&
        BLACKLISTED_TLDS.some((v) =>
          this.state.ssoEmail.toLowerCase().includes(v)
        )) ||
        !this.state.validated ||
        this.state.checked_enterprise)
    ) {
      event.preventDefault();
    }
  }

  handleEmailAddressChange(event) {
    let validated = false;
    const inputEmail = event.target.value;
    if (inputEmail.endsWith(".con")) {
      this.setState({
        ssoEmail: inputEmail,
        error: {
          message: "Did you mean .com instead of .con?",
          tooltip: null,
        },
      });
    } else {
      if (inputEmail.length > 1) {
        if (isEmail(inputEmail)) {
          validated = true;
        }
      }
      this.setState({
        error: {},
        ssoEmail: inputEmail,
        validated: validated,
        checked_enterprise: false,
      });
    }
  }

  handleSsoSubmit() {
    localStorage.setItem("localAuthMethod", "passwordless");
    const inputEmail = this.state.ssoEmail || "";
    const address = inputEmail.split("@").pop();
    this.props.apiCall({
      method: "GET",
      component: "CHECK_SSO_TLD_AND_GET_EMAIL_CODE",
      route: `enterprise_portals/tld_and_code/${address}/${inputEmail}`,
      bypassToken: true,
    });
    this.setState({
      checked_enterprise: true,
    });
  }

  render() {
    const fetching =
      this.props.fetchReducer.component === "CHECK_SSO_TLD_AND_GET_EMAIL_CODE" &&
      this.props.fetchReducer.fetching
    const networkError =
      this.props.fetchReducer.component === "CHECK_SSO_TLD_AND_GET_EMAIL_CODE" &&
      this.props.fetchReducer.error?.message?.startsWith("Network");
    const showPersonalEmailError =
      this.state.ssoEmail &&
      BLACKLISTED_TLDS.some((v) =>
        this.state.ssoEmail.toLowerCase().includes(v)
      );
    const show2StepAuth =
      this.state.loginWithSso &&
      this.props.checkEnterpriseTld &&
      this.props.checkEnterpriseTld.validated;

    if (!this.state.mounted) {
      return null;
    }

    return (
      <SubRightContainer>
        <SubRightContainer>
          {this.props.showLogo ? <Logo width="12em" height="7em" /> : null}
          {show2StepAuth ? (
            <SubContainer style={styles.subContainer}>
              <span>Please enter the 4 digit code we just sent to</span>
              <span style={styles.ssoEmail}>{this.state.ssoEmail}</span>
              <span>for a password-free sign in!</span>
            </SubContainer>
          ) : null}
          {this.state.loginWithSso ? (
            this.props.checkEnterpriseTld &&
            this.props.checkEnterpriseTld.validated ? (
              <SubContainer>
                {this.props.fetchReducer &&
                this.props.fetchReducer.fetching &&
                this.props.fetchReducer.component == "USER_LOGS_IN" ? (
                  <Loader svgMargin="1em" color={this.props.theme.grey3} />
                ) : this.props.dataUser &&
                  this.props.dataUser.error &&
                  this.state.verificationCode.length === CODE_LENGTH ? (
                  <ErrorMessage error="Oops! That's not the right code. Please try again." />
                ) : null}
                <ReactCodeInput
                  type="text"
                  fields={CODE_LENGTH}
                  autoFocus
                  autoComplete="off"
                  isValid={
                    this.state.verificationCode &&
                    this.state.verificationCode.length === CODE_LENGTH &&
                    this.props.dataUser &&
                    this.props.dataUser.error
                      ? false
                      : true
                  }
                  onChange={this.handleVerificationCodeChange}
                  disabled={
                    this.props.fetchReducer &&
                    this.props.fetchReducer.fetching &&
                    this.props.fetchReducer.component == "USER_LOGS_IN"
                  }
                />
              </SubContainer>
            ) : fetching ? (
              <Loader />
            ) : (
              <SubContainer>
                {this.state.error.message ? (
                  <ErrorMessage
                    error={this.state.error.message}
                    tooltip={this.state.error.tooltip}
                  />
                ) : showPersonalEmailError ? (
                  <ErrorMessage
                    tooltip="You can always login using your LinkedIn account. If your company is subscribed to our Enterprise plan, you'll also be able to login with your work email."
                    error="Please use your work email so we can connect you with your team on Shelfgram"
                  />
                ) : !this.state.checked_enterprise ||
                  fetching ||
                  (this.props.checkEnterpriseTld &&
                    this.props.checkEnterpriseTld.validated) ? null : this.props
                    .checkEnterpriseTld &&
                  this.props.checkEnterpriseTld.checked &&
                  this.props.checkEnterpriseTld.deactivated ? (
                  <ErrorMessage
                    error="Your company is no longer subscribed to our Enterprise plan. You can still login with LinkedIn to acccess our free plan."
                    tooltip="If you think might be a mistake, contact your Shelfgram Success Manager"
                  />
                ) : networkError && !this.props.checkEnterpriseTld?.message ? (
                  <ErrorMessage
                    tooltip="This can happen when corporate firewalls and VPN's haven't yet allowlisted Shelfgram."
                    error="Looks like there was a problem reaching our servers! Please contact your IT team ask them to allowlist Shelfgram."
                  />
                ) : this.props.checkEnterpriseTld &&
                  this.props.checkEnterpriseTld.free_email_tld ? (
                  <ErrorMessage
                    tooltip="You can always login using your LinkedIn account. If your company is subscribed to our Enterprise plan, you'll also be able to login with your work email."
                    error="Please use your work email so we can connect you with your team on Shelfgram"
                  />
                ) : this.props.checkEnterpriseTld &&
                  this.props.checkEnterpriseTld.redirect ? (
                  <Loader />
                ) : this.props.checkEnterpriseTld &&
                  this.props.checkEnterpriseTld.suggested_tld ? (
                  <ErrorMessage
                    error={`Did you mean ${this.props.checkEnterpriseTld.suggested_tld}?`}
                  />
                ) : this.props.forceRedirect ? (
                  <ErrorMessage error="It looks like your work email isn't yet supported by our service. We'd love to work with your company! Please contact us for partnership inquiries. You can also continue with LinkedIn to use our Free plan." />
                ) : (
                  this.props.showActivateAccount(this.state.ssoEmail)
                )}
                <Form onSubmit={this.handleSsoSubmit}>
                  <Input
                    error={
                      showPersonalEmailError ||
                      (this.props.checkEnterpriseTld &&
                        this.props.checkEnterpriseTld.free_email_tld)
                    }
                    type="email"
                    label="Email Address"
                    placeholder={`name@${this.props.placeholderTld || "company.com"}`}
                    value={this.state.ssoEmail}
                    autoComplete="email"
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    focused={this.state.isFocused}
                    onKeyPress={this.handleKeyPress}
                    onChange={this.handleEmailAddressChange}
                    style={{
                      textTransform: "none",
                      backgroundColor:
                        !this.state.checked_enterprise ||
                        fetching ||
                        this.props.checkEnterpriseTld
                          ? "white"
                          : "rgba(255,0,0,0.1)",
                    }}
                  />
                  {fetching ? (
                    <Loader />
                  ) : this.state.checked_enterprise ? null : (
                    <MainCTA
                      clickAction={this.handleSsoSubmit}
                      disabled={
                        showPersonalEmailError ||
                        !this.state.validated ||
                        (this.state.error && this.state.error.message)
                      }
                      width="70%"
                      type="button"
                      noMargin
                      text="Continue"
                    />
                  )}
                </Form>
                <Spacer />
                {this.state.checked_enterprise &&
                this.props.checkEnterpriseTld &&
                !this.props.checkEnterpriseTld.validated ? (
                  <LoginOption onClick={this.loginWithLinkedIn}>
                    <img src={`${awsPrefix}web-app/linkedin.svg`} />
                    Continue with LinkedIn
                  </LoginOption>
                ) : (
                  <Sso />
                )}
              </SubContainer>
            )
          ) : (
            <>
              <SubRightContainer>
                <SubContainer>
                  <LoginOption onClick={this.loginWithLinkedIn}>
                    <img src={`${awsPrefix}web-app/linkedin.svg`} />
                    Continue with LinkedIn
                  </LoginOption>
                  <LoginOption onClick={this.handleSsoClick}>
                    <FaEnvelope />
                    Continue with work email
                  </LoginOption>
                </SubContainer>
                <Spacer />
              </SubRightContainer>
            </>
          )}
          {this.state.onlyWork ? null : this.state.loginWithSso ? (
            <SubContainer>
              {(this.props.fetchReducer &&
                this.props.fetchReducer.fetching &&
                this.props.fetchReducer.component == "USER_LOGS_IN") ||
              this.props.fetchReducer.component ==
                "CHECK_SSO_TLD_AND_GET_EMAIL_CODE" ? (
                <Spacer style={styles.spacer} />
              ) : (
                <AltCTA
                  clickAction={this.handleSsoBack}
                  text="Back"
                  dark
                  icon={<FaAngleLeft />}
                />
              )}
            </SubContainer>
          ) : null}
        </SubRightContainer>
        <SubRightContainer>
          <SubContainer smallText style={styles.terms}>
            <p>
              By continuing, you agree to the{" "}
              <External
                href={`${companyWebsite}terms/`}
                target="_blank"
                rel="noreferrer noopener"
              >
                Terms{" "}
              </External>
              and{" "}
              <External
                href={`${companyWebsite}privacy/`}
                target="_blank"
                rel="noreferrer noopener"
              >
                Privacy Policy
              </External>
            </p>
          </SubContainer>
        </SubRightContainer>
        <SubContainer smallText>{copyright}</SubContainer>
      </SubRightContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  router: state.router,
  checkEnterpriseTld: state.checkEnterpriseTld,
  dataUser: state.dataUser,
  fetchReducer: state.fetchReducer
});

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) =>
    dispatch({ type: arg.type, data: arg.data, extraData: arg.extraData }),
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
});

export default withRouter(
  withTheme(connect(mapStateToProps, mapDispatchToProps)(AuthOptions))
);
