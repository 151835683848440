import AuthOptions from "components/auth-options/index";
import ActivateAccount from "screens/activate/index";
import styled, { withTheme, keyframes } from "styled-components";
import Logo from "components/logo/index";
import ProgressBar from "components/progress-bar/index";
import { PureComponent } from "react";
import { media } from "constants/responsive/index";
import { FaTimes } from "react-icons/fa";
import { awsPrefix, clearbitUrl, tools } from "constants/variables/index";
import { connect } from "react-redux";
import BrowserMock from "components/browser-mock/index";
import { featured } from "../../discover/curated/index.js";

const slideInFromRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 2em;
  ${media.tablet`
    width: 100%;
    justify-content: center;
    `}
  ${media.mobile`
    width: 100%;
    justify-content: center;
    `}
`;

const UserLogo = styled.img`
  border-radius: 0.75em;
  height: 3em;
  max-width: 11em;
  animation: fadeIn 1s;
  margin-left: 0.25em;
  padding: 0.5em;
  background: rgba(255, 255, 255, 0.6);
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: ${(props) => props.theme.grey6};
  height: 100%;
  ${media.tablet`
    padding-top: 10vh;
    height: 100%;
    background: ${(props) => props.theme.white};
    overflow: scroll;
  `};
  ${media.mobile`
    padding-top: 3vh;
    height: 100%;
    background: ${(props) => props.theme.white};
    overflow: scroll;
  `};
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: ${(props) => (props.showActivateAccount ? "1500px" : "1100px")};
  justify-content: space-between;
  position: relative;
  align-items: flex-start;
  ${media.tablet`
    justify-content: center; 
  `};
  ${media.mobile`
    justify-content: center;
  `};
`;

const LeftSide = styled.div`
  flex: 1;
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: ${(props) =>
    props.showActivateAccount ? "center" : "right"};
  position: relative;

  height: ${(props) => (props.showActivateAccount ? "80vh" : "auto")};
  padding-top: ${(props) => (props.showActivateAccount ? "none" : "22vh")};

  ${media.tablet`
    width: 100%;
    justify-content: center;
    margin-right: 0; 
  `}
  ${media.mobile`
    width: 100%;
    justify-content: center;
    margin-right: 0; 
  `}
`;

const RightSide = styled.div`
  flex: 1;
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: left;
  margin-left: 2em;
  position: relative;
  height: ${(props) => (props.showActivateAccount ? "80vh" : "auto")};
  padding-top: ${(props) => (props.showActivateAccount ? "none" : "22vh")};
  ${media.tablet`
    width: 100%;
    justify-content: center;
    margin-left: 0; 
  `}
  ${media.mobile`
    width: 100%;
    justify-content: center;
    margin-left: 0; 
  `}
`;

const ShadowContainer = styled.div`
  padding: 2em;
  display: flex;
  flex-wrap: wrap;
  transition: all 0.5s ease;
  width: 100%;
  max-width: ${(props) => (props.showActivateAccount ? "600px" : "450px")};
  animation: ${slideInFromRight} 0.5s ease;
  border-radius: 1em;
  background: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.notSubtleBoxShadow};
  ${media.mobile`
    box-shadow: none;
    margin-bottom: 8em;
    padding: 0.5em;
    width: 100%;
  `};
  ${media.tablet`
    box-shadow: none;
    padding: 0.5em;
    width: 100%;
    margin-bottom: 8em;
  `};
`;

const H2 = styled.h2`
  color: ${(props) => props.theme.grey1};
  margin: 0;
  animation: fadeIn 0.5s;
  padding-left: 8px;
  width: 100%;
  ${media.mobile`
    display: none;
  `};
  ${media.tablet`
    text-align: center;
    width: 100%;
    padding-left: 0;
    margin: 0 1em;
  `};
`;

const P = styled.p`
  padding: 0 8px;
  max-width: 24em;
  color: ${(props) => props.theme.grey2};
  ${media.mobile`
    text-align: center;
  `};
`;

const BrowserWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 700px;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.5s ease;
  animation: ${slideInFromRight} 0.5s ease;
`;

const CalendarWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 700px;
  height: 700px;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.5s ease;
  animation: ${slideInFromRight} 0.5s ease;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
`;

const styles = {
  userLogoX: {
    animation: "fadeIn 1s",
    margin: "0 1em",
  }
};

const constructHubspotUrl = (
  firstName,
  lastName,
  email,
  selectedIndustry,
  selectedPills
) => {
  const baseUrl = "https://meetings.hubspot.com/jackson-endacott/meeting-times";

  const queryParams = new URLSearchParams({
    firstname: firstName || "",
    lastname: lastName || "",
    email: email || "",
    industry: selectedIndustry || "", // hidden field: industry
    pill_selections: selectedPills.join(", ") || "", // hidden field: selected pills
  });

  return `${baseUrl}?embed=true&${queryParams.toString()}`;
};

const pillToToolsMap = {
  "Category Insights": ["Photos", "Planograms", "Circulars", "Analytics"],
  "Competitive Intelligence": ["Planograms", "Campaigns"],
  "Pricing Data": ["Price checks", "Analytics", "Circulars"],
  "Promotional Compliance": ["Analytics", "Circulars"],
  "Promotional Campaigns": ["Price checks", "Circulars"],
  Circulars: ["Circulars"],
  Marketing: ["Photos", "Campaigns"],
  "Shelf Availability": ["Planograms"],
  "Product Rollout": ["Planograms", "Circulars"],
  "Product Recall": ["Planograms"],
  "International Markets": ["Planograms", "Campaigns"],
};

const cardToFeaturedMap = {
  Manufacturer: ["Displays", "Beyond North America"],
  Retailer: ["Contests", "Checkout Area", "Limited Edition"],
  Broker: ["Shelf Signage", "Contests"],
  Agency: ["Displays", "QR Codes"],
};

const defaultCards = [
  { img: `${awsPrefix}web-app/default1.png` },
  { img: `${awsPrefix}web-app/default2.png` },
  { img: `${awsPrefix}web-app/default3.png` },
];

class DefaultLogin extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showActivateAccount: false,
      currentStep: 1,
      showBrowserMock: false,
      firstName: "",
      lastName: "",
      email: "",
      logo: "",
      userCompanyName: "",
      showCalendar: false,
      selectedIndustry: "",
      cardData: defaultCards,
      chipData: [],
      isDesktop: window.innerWidth >= 1024,
    };

    this.onError = this.onError.bind(this);
    this.showActivateAccount = this.showActivateAccount.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.updateScreenSize = this.updateScreenSize.bind(this);
    this.handleIndustryChange = this.handleIndustryChange.bind(this);
    this.handlePillDataChange = this.handlePillDataChange.bind(this);
  }

  handlePillDataChange(selectedPills) {
    let chipData = [];

    if (selectedPills.length === 0) {
      // No pills selected, render default chips without images
      chipData = [{ label: "" }, { label: "" }, { label: "" }];
    } else {
      // Get all unique tools for the selected pills
      const toolsForPills = new Set(
        selectedPills.flatMap((pill) => pillToToolsMap[pill] || [])
      );

      // Map the tool names to chipData
      chipData = Array.from(toolsForPills)
        .map((toolName) => {
          const tool = tools.find((t) => t.name === toolName);
          if (tool) {
            return {
              label: tool.name,
              img: `${awsPrefix}web-app/${tool.img}`,
            };
          }
        })
        .filter(Boolean);
    }

    this.setState({ chipData });
  }

  handleCardDataChange(industry) {
    let cardData = [];

    if (industry) {
      // Fetch the cards based on the selected industry
      const featuredCards = cardToFeaturedMap[industry] || []; // Get cards for the selected industry

      cardData = featured.filter((card) => featuredCards.includes(card.name));

      if (cardData.length === 0) {
        cardData = defaultCards.map((card, index) => ({
          name: `Default Card ${index + 1}`,
          backgroundIcon: card.img,
          primaryColor: "rgba(255, 255, 255, 0.2)",
          secondaryColor: "rgba(220, 220, 220, 0.2)",
        }));
      }
    } else {
      cardData = defaultCards.map((card, index) => ({
        name: `Default Card ${index + 1}`,
        backgroundIcon: card.img,
        primaryColor: "rgba(255, 255, 255, 0.2)",
        secondaryColor: "rgba(220, 220, 220, 0.2)",
      }));
    }

    this.setState({ cardData });
  }

  handleIndustryChange(industry) {
    this.handleCardDataChange(industry);
    this.setState({ selectedIndustry: industry });
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateScreenSize);
    this.setState({
      chipData: [
        { label: "", isDefault: true },
        { label: "", isDefault: true },
        { label: "", isDefault: true },
      ],
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScreenSize);
  }

  updateScreenSize() {
    this.setState({ isDesktop: window.innerWidth >= 1024 });
  }

  showActivateAccount(ssoEmail) {
    this.setState({
      showActivateAccount: true,
      showBrowserMock: true,
      email: ssoEmail,
    });
  }

  handleBack() {
    if (this.state.currentStep === 1) {
      this.setState({
        showActivateAccount: false,
        showBrowserMock: false,
        showCalendar: false,
      });
    } else {
      this.setState((prevState) => ({
        currentStep: prevState.currentStep - 1,
        showCalendar:
          prevState.currentStep === 5 ? false : prevState.showCalendar,
        showBrowserMock:
          prevState.currentStep === 5 ? true : prevState.showBrowserMock,
      }));
    }
  }

  handleNextStep() {
    this.setState((prevState) => ({
      currentStep: prevState.currentStep + 1,
      showBrowserMock: prevState.currentStep < 4,
      showCalendar: prevState.currentStep === 4,
    }));
  }

  handleNameChange(firstName, lastName) {
    this.setState({ firstName, lastName });
  }

  onError() {
    this.props.setData({ type: "ERROR_LOADING_COMPANY_IMG" });
  }

  render() {
    const {
      showActivateAccount,
      currentStep,
      showBrowserMock,
      firstName,
      lastName,
      userCompanyName,
      email,
      logo,
      showCalendar,
      isDesktop,
      selectedIndustry,
      cardData,
      chipData,
    } = this.state;

    const companyName = this.props.serverData && this.props.serverData.name;

    const tld =
      this.props.loginData &&
      this.props.loginData.login_tld &&
      this.props.loginData.login_tld.active
        ? this.props.loginData.login_tld.tld
        : null;

    const hubspotFormUrl = constructHubspotUrl(
      firstName,
      lastName,
      email,
      selectedIndustry,
      chipData.map((chip) => chip.label)
    );

    return (
      <Wrapper>
        {showActivateAccount && (
          <ProgressBar currentScreen={currentStep} totalScreens={5} />
        )}

        {isDesktop ? (
          <>
            <InnerWrapper showActivateAccount={showActivateAccount}>
              <LeftSide showActivateAccount={showActivateAccount}>
                {!showActivateAccount && (
                  <LogoWrapper>
                    <Logo
                      width={companyName || tld ? "10em" : "16em"}
                      height="7em"
                    />
                    {this.props.loginData &&
                    this.props.loginData.error ? null : companyName || tld ? (
                      <>
                        <FaTimes
                          size="1em"
                          color={this.props.theme.grey1}
                          style={styles.userLogoX}
                        />
                        <UserLogo
                          onError={this.onError}
                          src={
                            tld
                              ? `${clearbitUrl}${tld}`
                              : `${awsPrefix}companies/${this.props.serverData.companyImg}`
                          }
                        />
                      </>
                    ) : null}
                    {companyName || tld ? (
                      <>
                        {companyName ? (
                          <H2>{companyName}</H2>
                        ) : (
                          <H2>Join your team on Shelfgram</H2>
                        )}
                        <P>
                          Your colleagues are already finding retail insights
                          faster and easier than ever before.
                        </P>
                      </>
                    ) : (
                      <H2>The virtual store walk platform.</H2>
                    )}
                  </LogoWrapper>
                )}
                {showActivateAccount && (
                  <ShadowContainer showActivateAccount={showActivateAccount}>
                    <ActivateAccount
                      onBack={this.handleBack}
                      onNextStep={this.handleNextStep}
                      currentStep={currentStep}
                      onNameChange={this.handleNameChange}
                      onLogoChange={(logo) => this.setState({ logo })}
                      onIndustryChange={this.handleIndustryChange}
                      onPillDataChange={this.handlePillDataChange}
                    />
                  </ShadowContainer>
                )}
              </LeftSide>

              <RightSide showActivateAccount={showActivateAccount}>
                {!showActivateAccount && (
                  <ShadowContainer showActivateAccount={showActivateAccount}>
                    <AuthOptions
                      placeholderTld={this.props.serverData?.tld}
                      showActivateAccount={this.showActivateAccount}
                    />
                  </ShadowContainer>
                )}
                {showBrowserMock && (
                  <BrowserWrapper show={showBrowserMock}>
                    <BrowserMock
                      firstName={firstName}
                      lastName={lastName}
                      logo={logo}
                      userCompanyName={userCompanyName}
                      selectedIndustry={selectedIndustry}
                      cardData={cardData}
                      chipData={chipData}
                    />
                  </BrowserWrapper>
                )}
                {showCalendar && (
                  <CalendarWrapper show={showCalendar}>
                    <StyledIframe
                      src={hubspotFormUrl}
                      title="Schedule a Setup Call"
                    />
                  </CalendarWrapper>
                )}
              </RightSide>
            </InnerWrapper>
          </>
        ) : (
          <>
            <InnerWrapper>
              {!showActivateAccount && (
                <LogoWrapper>
                  <Logo
                    width={companyName || tld ? "10em" : "16em"}
                    height="7em"
                  />
                  {this.props.loginData &&
                  this.props.loginData.error ? null : companyName || tld ? (
                    <>
                      <FaTimes
                        size="1em"
                        color={this.props.theme.grey1}
                        style={styles.userLogoX}
                      />
                      <UserLogo
                        onError={this.onError}
                        src={
                          tld
                            ? `${clearbitUrl}${tld}`
                            : `${awsPrefix}companies/${this.props.serverData.companyImg}`
                        }
                      />
                    </>
                  ) : null}
                  {companyName || tld ? (
                    <>
                      {companyName ? (
                        <H2>{companyName}</H2>
                      ) : (
                        <H2>Join your team on Shelfgram</H2>
                      )}
                      <P>
                        Your colleagues are already finding retail insights
                        faster and easier than ever before.
                      </P>
                    </>
                  ) : (
                    <H2>The virtual store walk platform.</H2>
                  )}
                </LogoWrapper>
              )}
              {!showActivateAccount && (
                <ShadowContainer showActivateAccount={showActivateAccount}>
                  <AuthOptions
                    placeholderTld={this.props.serverData?.tld}
                    showActivateAccount={this.showActivateAccount}
                  />
                </ShadowContainer>
              )}
              {showActivateAccount && (
                <ShadowContainer showActivateAccount={showActivateAccount}>
                  <ActivateAccount
                    onBack={this.handleBack}
                    onNextStep={this.handleNextStep}
                    currentStep={currentStep}
                    onNameChange={this.handleNameChange}
                    onLogoChange={(logo) => this.setState({ logo })}
                    onIndustryChange={this.handleIndustryChange}
                    onPillDataChange={this.handlePillDataChange}
                  />
                  {showCalendar && (
                    <CalendarWrapper show={showCalendar}>
                      <StyledIframe
                        src={hubspotFormUrl}
                        title="Schedule a Setup Call"
                      />
                    </CalendarWrapper>
                  )}
                </ShadowContainer>
              )}
            </InnerWrapper>
          </>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  loginData: state.loginData,
});

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) => dispatch({ type: arg.type, data: arg.data }),
});

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(DefaultLogin)
);
