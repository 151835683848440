import { Container } from "constants/styled-components/index";
import { PureComponent } from "react";
import {
  FaFileArchive,
  FaFileExcel,
  FaFilePowerpoint,
  FaFolderOpen,
  FaTrash,
  FaLink,
  FaTimes,
} from "react-icons/fa";
import Desktop from "constants/responsive/desktop/index";
import Tooltip from "components/tooltip/index";
import { TooltipText } from "constants/styled-components/index";
import DesktopOrTablet from "constants/responsive/desktop-or-tablet/index";
import {
  awsPhotosPrefixS3,
  appPrefix,
  tooltipDelayLong, validPlanIdsForDelete,
} from "constants/variables/index";
import Mobile from "constants/responsive/mobile/index";
import Loader from "components/loader/index";
import { connect } from "react-redux";
import moment from "moment";
import styled, { withTheme } from "styled-components";
import { excelExport } from "services/download-excel/index";
import { zipTheFeed } from "services/download-zip/index";
import UpgradeIcon from "components/upgrade-icon/index";
import { showModal } from "@redux/slices/modalSlice";
import { Modals } from "components/modal/Modal.constants";

const SelectBottomBar = styled.div`
  position: fixed;
  font-size: 0.8em;
  box-shadow: 0px 0px 20px 20px ${(props) => props.theme.veryLightGrey};
  bottom: 0;
  left: 0;
  background: ${(props) => props.theme.veryLightGrey};
  animation: fadeIn 1s;
  width: 100%;
  box-sizing: border-box;
  padding: 2em 1em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  svg {
    margin-top: 0 !important;
  }
`;
const Action = styled.div`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  transition: ${(props) => props.theme.fastTransition};
  padding: 0 1em;
  .upgradeIcon {
    padding: 0.5em;
  }
  p {
    color: ${(props) => props.theme.grey2};
  }
  svg {
    transition: ${(props) => props.theme.defaultTransition};
    fill: ${(props) => props.theme.grey2};
    min-width: 1.5em;
    min-height: 1.5em;
    margin-left: 0.5em;
  }
  &:hover {
    background-color: ${(props) => props.theme.grey5} !important;
    p {
      color: ${(props) => props.theme.grey1};
    }
    svg {
      fill: ${(props) => props.theme.grey1};
    }
  }
`;
const Group = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
const Actions = styled.div`
  position: absolute;
  animation: slide-from-top-big 0.5s;
  left: 0;
  z-index: -1;
  height: 5em;
  bottom: -5em;
  right: 0;
  font-size: 0.8em;
  display: flex;
  box-shadow: ${(props) => props.theme.sortOfSubtleBoxShadow};
  transition: ${(props) => props.theme.defaultTransition};
  align-items: center;
  background: ${(props) => props.theme.grey6};
  justify-content: space-between;
  .closeContainer {
    padding: 0 2em;
    svg {
      fill: ${(props) => props.theme.midRed};
      margin-left: 0;
    }
  }
  &:hover {
    .closeContainer {
      svg {
        fill: ${(props) => props.theme.deepRed};
      }
    }
  }
`;
const P = styled.p`
  margin: 0;
  cursor: pointer;
  font-weight: bold;
  width: max-content;
  font-size: 1.5em;
  transition: ${(props) => props.theme.defaultTransition};
  padding: 0.5em;
`;

const styles = {
  tooltip: {
    height: "100%",
    display: "flex",
  },
  link: {
    color: "white",
    width: "90%",
    fontSize: "1.25em",
  },
  cancel: {
    color: "white",
    width: "30%",
    fontSize: "1.25em",
  },
};

class MultipleSelectMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      zipping: false,
      zipProcessed: 0,
    };
    this.showShareMenu = this.showShareMenu.bind(this);
    this.selectShare = this.selectShare.bind(this);
    this.cancelMultipleSelect = this.cancelMultipleSelect.bind(this);
    this.handleEnterpriseGate = this.handleEnterpriseGate.bind(this);
    this.showPowerpointExport = this.showPowerpointExport.bind(this);
    this.selectCollection = this.selectCollection.bind(this);
    this.deletePhotos = this.deletePhotos.bind(this);
    this.selectDownload = this.selectDownload.bind(this);
    this.handleDownloadExcel = this.handleDownloadExcel.bind(this);
  }

  componentDidMount() {
    if (this.props.initialDate) {
      const currentDate = moment(Date.now());
      const selectionDate = moment(this.props.initialDate);
      if (currentDate.diff(selectionDate, "hours") > 20) {
        this.cancelMultipleSelect();
      }
    }
    if (this.props.data) {
      this.handleFeatureFound();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.data && this.props.data) {
      this.handleFeatureFound();
    }
    if (this.props.shareModalIsOpen && prevProps.data !== this.props.data) {
      this.showShareMenu();
    }
  }

  handleFeatureFound() {
    if (!this.props.dataUser?.feature_found_multiple_select) {
      this.props.apiCall({
        method: "POST",
        component: "FEATURE_FOUND_MULTIPLE_SELECT",
        route: "user/feature_found",
        body: {
          user_id: this.props.dataUser.id,
          feature_id: 6,
        },
      });
    }
  }

  handleEnterpriseGate() {
    this.props.showModal({ component: Modals.UPGRADE_FLASH })
  }
  handleDownloadExcel() {
    if (this.props.isFlyer) {
      excelExport(
        [
          {
            name: "Exported Circulars",
            data: this.props.data.map((x) => ({
              Retailer: x.retailer,
              Start: x.start_date,
              End: x.end_date,
              Page: x.page_num,
              Link: {
                text: `${appPrefix}f/${x.img}`,
                hyperlink: `${appPrefix}f/${x.img}`,
                tooltip: `${appPrefix}f/${x.img}`,
              },
            })),
          },
        ],
        "Exported Circulars"
      );
    } else {
      excelExport(
        [
          {
            name: "Exported Photos",
            data: this.props.data.map((x) => ({
              "Date Taken": new Date(x.date_taken),
              Retailer: x.retailer,
              Location: `${x.store_num ? `Store #${x.store_num} - ` : ""}${
                x.address
              }`,
              City: x.city,
              State: x.state,
              Country: x.country,
              Link: {
                text: `${appPrefix}s/${x.img}`,
                hyperlink: `${appPrefix}s/${x.img}`,
                tooltip: `${appPrefix}s/${x.img}`,
              },
            })),
          },
        ],
        "Exported Photos"
      );
    }
  }
  selectDownload() {
    this.setState({ zipping: true }, () =>
      this.props.isPlanogram
        ? this.props.data.map((pog) =>
            zipTheFeed(
              pog.photos.map((x) => `${awsPhotosPrefixS3}photos/${x.img}`),
              `${this.props.dataUser.first_name} ${this.props.dataUser.last_name}`,
              pog.photos.map((z) => ({
                ...pog,
              })),
              this.finishedZippedFunc.bind(this),
              "Planograms"
            )
          )
        : zipTheFeed(
            this.props.data.map(
              (x) =>
                `${awsPhotosPrefixS3}${
                  this.props.isFlyer ? "flyers" : "photos"
                }/${x.img}`
            ),
            `${this.props.dataUser.first_name} ${this.props.dataUser.last_name}`,
            this.props.data,
            this.finishedZippedFunc.bind(this),
            this.props.isFlyer ? "Flyers" : "Photos"
          )
    );
  }
  finishedZippedFunc() {
    this.setState({
      zipping: false,
    });
  }
  showPowerpointExport() {
    this.props.showModal({
      component: Modals.POWERPOINT_EXPORT,
      data: {
        photos: this.props.data,
        isFlyer: this.props.isFlyer,
        isPlanogram: this.props.isPlanogram,
        presentationName: this.props.isPlanogram
          ? "Planogram Report"
          : this.props.isFlyer
          ? "Flyers Report"
          : "Photos Report",
      },
    });
  }
  cancelMultipleSelect() {
    this.props.setData({
      type: `RESET_SELECT_MULTIPLE_${
        this.props.isPlanogram
          ? "PLANOGRAMS"
          : this.props.isFlyer
          ? "FLYERS"
          : "PHOTOS"
      }`,
    });
  }
  selectShare() {
    this.props.showModal({
      component: Modals.SHARE,
      data: {
        isPlanogram: this.props.isPlanogram,
        isFlyer: this.props.isFlyer,
        data: this.props.data,
      },
    });
  }
  selectCollection() {
    this.props.showModal({
      component: Modals.ADD_TO_COLLECTION,
      data: { photos: this.props.data },
    });
  }
  showShareMenu() {
    this.props.showModal({
      component: Modals.FEED_SHARE_MENU,
      data: {
        isFlyer: this.props.isFlyer,
        isPlanogram: this.props.isPlanogram,
        data: this.props.data,
      },
    });
  }
  deletePhotos() {
    this.props.showModal({
      component: Modals.DELETE,
      data: { photos: this.props.data }
    });
  }

  render() {
    const descriptor =
      this.props.data && this.props.data.length > 1
        ? this.props.isPlanogram
          ? "planograms"
          : this.props.isFlyer
          ? "circulars"
          : "photos"
        : this.props.isPlanogram
        ? "planogram"
        : this.props.isFlyer
        ? "circular"
        : "photo";
    const isFreePlan = this.props.dataUser && this.props.dataUser.plan_id === 1;
    const showDelete = (
      !this.props.isFlyer &&
      !this.props.isPlanogram &&
      (
        validPlanIdsForDelete.includes(this.props.dataUser.plan_id) ||
        this.props.data.every(photo => photo.uploader === this.props.dataUser.id)
      )
    );
    if (
      this.props.showFeedNavMenu ||
      this.props.showPlanogramMoreMenu ||
      this.props.showFeedCompanyTypeMenu ||
      this.props.showFeedMoreMenu ||
      this.props.showFeedShareMenu ||
      this.props.showFlyerFilterMenu ||
      this.props.showFeedTypeMenu
    ) {
      return null;
    }
    return (
      <>
        <DesktopOrTablet
          content={
            <Actions primaryColor={this.props.primaryColor}>
              <Group>
                <Action onClick={this.showShareMenu}>
                  <P>
                    {`${this.props.data.length} ${descriptor} ${
                      this.props.translations["66"] || "selected"
                    }`}
                  </P>
                </Action>
              </Group>
              <Group>
                <Action onClick={this.selectShare}>
                  <FaLink />
                  <P>Share a link</P>
                </Action>
                <Action
                  onClick={
                    this.state.zipping
                      ? null
                      : isFreePlan
                      ? this.handleEnterpriseGate
                      : this.selectDownload
                  }
                >
                  {isFreePlan ? (
                    <UpgradeIcon />
                  ) : this.state.zipping && this.props.data.length > 3 ? (
                    <Loader
                      width="auto"
                      size="1em"
                      svgMargin="0"
                      padding="0"
                      color={this.props.theme.white}
                    />
                  ) : (
                    <FaFileArchive />
                  )}
                  <Desktop
                    content={
                      <P>
                        {this.state.zipping && this.props.data.length > 3
                          ? this.state.zipProcessed > 0
                            ? `Zipping ${this.state.zipProcessed} of ${this.props.data.length}`
                            : "Zipping..."
                          : "Export to ZIP"}
                      </P>
                    }
                  />
                </Action>
                <Action
                  onClick={
                    isFreePlan ? this.handleEnterpriseGate : this.showPowerpointExport
                  }
                >
                  {isFreePlan ? <UpgradeIcon /> : <FaFilePowerpoint />}
                  <Desktop content={<P>Export to PowerPoint</P>} />
                </Action>
                {this.props.isPlanogram ? null : (
                  <Action
                    onClick={
                      isFreePlan
                        ? this.handleEnterpriseGate
                        : this.handleDownloadExcel
                    }
                  >
                    {isFreePlan ? <UpgradeIcon /> : <FaFileExcel />}
                    <Desktop content={<P>Export to Excel</P>} />
                  </Action>
                )}
                {this.props.isFlyer || this.props.isPlanogram ? null : (
                  <Action onClick={this.selectCollection}>
                    <FaFolderOpen />
                    <Desktop content={<P>Add to collection</P>} />
                  </Action>
                )}
                {showDelete && (
                  <Action onClick={this.deletePhotos}>
                    <FaTrash/>
                    <Desktop content={<P>Delete</P>}/>
                  </Action>
                )}
                <Tooltip
                  unmountHTMLWhenHide
                  html={
                    <TooltipText maxWidth="800px">Clear selection</TooltipText>
                  }
                  position="bottom"
                  delay={tooltipDelayLong}
                  trigger="mouseenter"
                  style={styles.tooltip}
                >
                  <Action
                    className="closeContainer"
                    onClick={this.cancelMultipleSelect}
                  >
                    <FaTimes />
                  </Action>
                </Tooltip>
              </Group>
            </Actions>
          }
        />
        <Mobile
          content={
            <SelectBottomBar>
              <Container
                onClick={this.selectShare}
                style={{ ...styles.link }}
                primaryColor={this.props.theme.deepBlue}
              >
                <FaLink />
                {`Share a link to ${this.props.data.length} ${descriptor}`}
              </Container>
              <Container
                style={{ ...styles.cancel }}
                primaryColor={this.props.theme.deepRed}
                onClick={this.cancelMultipleSelect}
              >
                Cancel
              </Container>
            </SelectBottomBar>
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataUser: state.dataUser,
  dataLandingPageAbout: state.dataLandingPageAbout,
  translations: state.translations,
  showFeedNavMenu: state.showFeedNavMenu,
  showPlanogramMoreMenu: state.showPlanogramMoreMenu,
  showFeedCompanyTypeMenu: state.showFeedCompanyTypeMenu,
  showFeedMoreMenu: state.showFeedMoreMenu,
  showFeedShareMenu: state.showFeedShareMenu,
  showFlyerFilterMenu: state.showFlyerFilterMenu,
  showFeedTypeMenu: state.showFeedTypeMenu,
  shareModalIsOpen: state.modal?.component === Modals.FEED_SHARE_MENU,
});

const mapDispatchToProps = (dispatch) => ({
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
  setData: (arg) =>
    dispatch({ type: arg.type, data: arg.data, extraData: arg.extraData }),
  showModal: (payload) => dispatch(showModal(payload))
});

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(MultipleSelectMenu)
);
