var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ToggleSwitch from "components/toggle-switch";
import Tooltip from "components/tooltip";
import { Flex, TooltipText } from "constants/styled-components";
import { FaInfoCircle } from "react-icons/fa";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectUserData } from "@redux/selectors/user";
import { toggleAdvancedFilters } from "@redux/api/endpoints/users";
import styled from "styled-components";
import { useEffect, useState } from "react";
var Wrapper = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: ", ";\n\n  svg {\n    fill: ", ";\n  }\n"], ["\n  padding: ", ";\n\n  svg {\n    fill: ", ";\n  }\n"])), function (props) { return props.noPadding ? undefined : "0 1em"; }, function (_a) {
    var theme = _a.theme;
    return theme.grey3;
});
export var AdvancedFeedFiltersToggle = function (_a) {
    var tooltip = _a.tooltip, noPadding = _a.noPadding, onToggle = _a.onToggle;
    var useAdvancedFilters = useAppSelector(function (state) { var _a; return !!((_a = selectUserData(state)) === null || _a === void 0 ? void 0 : _a.use_advanced_filters); });
    var _b = useState(useAdvancedFilters), toggledOn = _b[0], setToggledOn = _b[1];
    var dispatch = useAppDispatch();
    useEffect(function () { return setToggledOn(useAdvancedFilters); }, [useAdvancedFilters]);
    var handleToggle = function (toggledOn) {
        if (typeof onToggle === "function") {
            setToggledOn(toggledOn);
            onToggle(toggledOn);
        }
        else {
            dispatch(toggleAdvancedFilters({
                body: { active: toggledOn }
            }));
        }
    };
    return (_jsxs(Wrapper, { width: "auto", noPadding: noPadding, children: [_jsx(ToggleSwitch, { clickAction: handleToggle, toggledOn: toggledOn }), tooltip && (_jsx(Tooltip, { unmountHTMLWhenHide: true, html: _jsx(TooltipText, { children: "Enable OR logic to create alternate sets of criteria" }), position: "top", width: "auto", children: _jsx(FaInfoCircle, {}) }))] }));
};
var templateObject_1;
