import { PureComponent } from "react";
import styled, { withTheme } from "styled-components";
import DesktopOrTablet from "constants/responsive/desktop-or-tablet/index";
import AuthOptions from "components/auth-options/index";
import DocumentTitle from "react-document-title";
import Loadable from "react-loadable";
import Loader from "components/loader/index";
import { awsPrefix } from "constants/variables/index";
import { connect } from "react-redux";
import { media } from "constants/responsive/index";
import toast from "react-hot-toast";
import DefaultLogin from "./default/index";

const Map = Loadable({
  loader: async () =>
    import(
      /* webpackChunkName: "dynamic-BackgroundMap" */ "./backgrounds/map/index"
    ),
  loading() {
    return <Loader color="white" />;
  },
});

const BackgroundImage = Loadable({
  loader: async () =>
    import(
      /* webpackChunkName: "dynamic-BackgroundImage" */ "./backgrounds/image/index"
    ),
  loading() {
    return <Loader color="white" />;
  },
});

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  animation: fadeIn 0.5s;
  background: ${(props) => props.theme.white};
  overflow: hidden;
`;
const LeftBanner = styled.div`
  transition: ${(props) => props.theme.defaultTransition};
  overflow: hidden;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  filter: grayscale(0.3);
  box-shadow: inset 0px 0px 190px rgba(0, 0, 0, 0.8);
  display: flex;
  flex-wrap: wrap;
  flex: 1.5;
  justify-content: center;
  pointer-events: none;
  align-items: flex-end;
  background-color: #666;
  background-blend-mode: color-burn;
`;
const RightBanner = styled.div`
  transition: ${(props) => props.theme.defaultTransition};
  max-width: 400px;
  position: absolute;
  right: 0;
  background: ${(props) => props.theme.white};
  animation: slide-from-right 0.5s;
  height: 100%;
  overflow: auto;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 1em;
  ${media.mobile`
    max-width: 100%;
    animation: fadeIn 0.5s;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    background: ${(props) => props.theme.white};
    box-sizing: border-box;
    box-shadow: 0px 5px 20px rgba(0,0,0,0.5);
  `};
`;
const H1 = styled.h1`
  margin: 0;
  padding: 1em 2.35em;
  font-weight: normal;
  text-shadow: 3px 3px 11px #222;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  font-size: 1.25em;
  color: whitesmoke;
  text-rendering: optimizelegibility;
  text-align: left;
  width: 100%;
  justify-content: flex-start;
`;
const FullWidthDivBanner = styled.div`
  width: 100%;
  position: ${(props) => (props.position ? props.position : "relative")};
  z-index: 2;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
`;
const Profile = styled.div`
  min-width: ${(props) => (props.flag ? "9em" : "5em")};
  height: 5em;
  background: white;
  border-radius: 5px;
  margin: 1em;
  box-shadow: 3px 3px 9px #222;
`;
const Img = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: 95%;
  background-repeat: no-repeat;
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  padding: 1em;
`;
const Wrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 1em;
`;
const GradientColor = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: ${(props) =>
    props.primaryColor
      ? `linear-gradient(45deg, rgb${props.primaryColor}, ${props.theme.midRed},${props.theme.midBlue})`
      : `linear-gradient(45deg, black, grey)`};
  opacity: 0.6;
`;

const styles = {
  title: {
    fontWeight: "bold",
    padding: "0",
    fontSize: "1.5em",
  },
  subTitle: {
    padding: "1em 0 0 0",
  },
};

class LoginPage extends PureComponent {
  componentDidMount() {
    toast.dismiss();
    if (
      window.location &&
      window.location.pathname &&
      window.location.pathname.substring(0, 4) == "/lp/" &&
      !window.location.pathname.includes("/uploaded/") &&
      !window.location.pathname.includes("/saved/") &&
      !this.props.dataLoginPreview
    ) {
      const pathname = window.location.pathname.split("/");
      this.props.apiCall({
        method: "GET",
        component: "LOGIN_PREVIEW",
        route: "login/preview",
        queryParams: {
          lp_type: pathname[2],
          slug: pathname[3],
        },
      });
    }
  }

  render() {
    const isFlyer = window.location.pathname.includes("/flyers/");
    const isLandingPage =
      window.location &&
      window.location.pathname &&
      window.location.pathname.substring(0, 4) == "/lp/";
    return (
      <DocumentTitle title="Login - Shelfgram">
        {isLandingPage ? (
          <Wrapper>
            <DesktopOrTablet
              content={
                <LeftBanner>
                  <GradientColor
                    primaryColor={
                      isFlyer
                        ? "(255,100,100)"
                        : this.props.dataLoginPreview.primary_color
                    }
                  />
                  {!window.location.pathname.includes("/saved/") &&
                  !window.location.pathname.includes("/uploaded/") &&
                  !(
                    this.props.dataLoginPreview &&
                    this.props.dataLoginPreview.bad_request
                  ) ? (
                    this.props.dataLoginPreview ? (
                      this.props.dataLoginPreview.suffix ? (
                        <>
                          <FullWidthDivBanner
                            position={
                              this.props.dataLoginPreview.zoom
                                ? "absolute"
                                : null
                            }
                          >
                            <Flex>
                              <Profile
                                flag={
                                  this.props.dataLoginPreview.source ==
                                  "countries"
                                }
                              >
                                <Img
                                  style={{
                                    backgroundImage: `url("${awsPrefix}${this.props.dataLoginPreview.source}/${this.props.dataLoginPreview.img}")`,
                                  }}
                                />
                              </Profile>
                              <Wrap>
                                <H1 style={styles.title}>
                                  {this.props.dataLoginPreview.name}
                                </H1>
                              </Wrap>
                            </Flex>
                          </FullWidthDivBanner>
                          {this.props.dataLoginPreview.zoom &&
                          this.props.dataLoginPreview.lat ? (
                            <Map
                              center={[
                                this.props.dataLoginPreview.lat,
                                this.props.dataLoginPreview.lng,
                              ]}
                              zoom={this.props.dataLoginPreview.zoom}
                            />
                          ) : (
                            <BackgroundImage
                              src={
                                this.props.dataLoginPreview.lp_background_img &&
                                this.props.dataLoginPreview.lp_background_img.includes(
                                  "http"
                                )
                                  ? this.props.dataLoginPreview
                                      .lp_background_img
                                  : this.props.dataLoginPreview
                                      .lp_background_img
                                  ? `${awsPrefix}lp_backgrounds/${this.props.dataLoginPreview.lp_background_img}`
                                  : `${awsPrefix}${this.props.dataLoginPreview.source}/${this.props.dataLoginPreview.img}`
                              }
                              placeholder={
                                this.props.dataLoginPreview.lp_background_img &&
                                this.props.dataLoginPreview.lp_background_img.includes(
                                  "http"
                                )
                                  ? this.props.dataLoginPreview
                                      .lp_background_img
                                  : this.props.dataLoginPreview
                                      .lp_background_img
                                  ? `${awsPrefix}lp_backgrounds/${this.props.dataLoginPreview.lp_background_img}-C`
                                  : `${awsPrefix}${this.props.dataLoginPreview.source}/${this.props.dataLoginPreview.img}`
                              }
                            />
                          )}
                        </>
                      ) : (
                        <BackgroundImage
                          src={`${awsPrefix}web-app/composite-masonry.jpg`}
                        />
                      )
                    ) : (
                      <Loader />
                    )
                  ) : (
                    <BackgroundImage
                      src={
                        isFlyer
                          ? `${awsPrefix}web-app/flyer.jpeg`
                          : `${awsPrefix}web-app/composite-masonry.jpg`
                      }
                      placeholder={
                        isFlyer
                          ? `${awsPrefix}web-app/flyer.jpeg`
                          : `${awsPrefix}web-app/composite-masonry-C.jpg`
                      }
                    />
                  )}
                </LeftBanner>
              }
            />
            <RightBanner>
              <AuthOptions showLogo forceRedirect />
            </RightBanner>
          </Wrapper>
        ) : (
          <DefaultLogin serverData={this.props.serverData} />
        )}
      </DocumentTitle>
    );
  }
}

const mapStateToProps = (state) => ({
  dataLoginPreview: state.dataLoginPreview,
  router: state.router,
});

const mapDispatchToProps = (dispatch) => ({
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
});

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage)
);
