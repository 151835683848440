import { selectAPICallIsError, selectAPICallIsSuccess, selectAPICallIsFetching, selectAPICallError, clearApiCallError } from "@redux/slices/apiCallsSlice";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { getComponentName } from "@redux/api/createApiThunk";
import { useCallback, useMemo } from "react";
export var useApiCallStatus = function (componentToCheck, suffix) {
    var component = useMemo(function () {
        if (typeof componentToCheck === "string") {
            return componentToCheck;
        }
        return getComponentName(componentToCheck.typePrefix.replace(/^api\//, ""), { componentSuffix: suffix });
    }, [componentToCheck, suffix]);
    var isFetching = useAppSelector(function (state) { return selectAPICallIsFetching(state, component); });
    var isSuccess = useAppSelector(function (state) { return selectAPICallIsSuccess(state, component); });
    var isError = useAppSelector(function (state) { return selectAPICallIsError(state, component); });
    var error = useAppSelector(function (state) { return selectAPICallError(state, component); });
    var dispatch = useAppDispatch();
    var resetApiCallError = useCallback(function () { return dispatch(clearApiCallError(component)); }, [dispatch, component]);
    return { resetApiCallError: resetApiCallError, isFetching: isFetching, isError: isError, isSuccess: isSuccess, error: error };
};
