import { PureComponent } from "react";
import {
  awsPrefix,
  demoLink,
  styleSmallFont,
  socialProofLogos,
} from "constants/variables";

import MainCTA from "components/buttons/main";
import Tilt from "react-parallax-tilt";
import { connect } from "react-redux";
import { media } from "constants/responsive";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 0.5em 0.5em 2em 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.grey6};
  ${media.mobile`
    flex-wrap: wrap;
    padding: 0.5em;
  `}
`;
const Container = styled.div`
  width: 50%;
  min-width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-right: 1em;
  ${media.mobile`
    width: 100%;
    padding-left: 1em;
  `}
`;
const Graphic = styled.div`
  width: 26em;
  height: 20em;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  animation: fadeIn 1s;
  ${media.mobile`
    display: none;
  `}
`;
const SocialProofLogo = styled.div`
  height: 1.75em;
  width: 2em;
  margin: 0.5em;
  padding: 0.5em;
  border-radius: 3px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.4;
`;
const Trusted = styled.span`
  font-size: 0.8em;
  font-weight: bold;
  width: 100%;
  padding-top: 2em;
  padding-bottom: 0.5em;
  text-transform: uppercase;
  text-align: center;
  color: ${(props) => props.theme.grey3};
`;
const A = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const Text = styled.span`
  padding-left: 1em;
  strong {
    cursor: pointer;
  }
  ${media.mobile`
    padding-left: 0;
  `}
`;
const Li = styled.li`
  color: ${(props) => props.theme.grey2};
  padding: 0.75em 0;
  list-style: none;
  line-height: 1.25em;
  display: flex;
  align-items: center;
  svg {
    min-height: 1em;
    min-width: 1em;
    fill: ${(props) => props.theme.grey3};
  }
`;
const Ul = styled.ul`
  padding-left: 1em;
`;
const SocialProof = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 26em;
  li {
    list-style: none;
  }
`;

class UpgradePrompt extends PureComponent {
  componentDidMount() {
    this.props.setData({ type: "HIDE_FREE_PLAN_BANNER" });
  }

  render() {
    return (
      <Wrapper>
        <Container>
          <Ul>
            <Li>
              <Text>
                Get company-wide access to weekly nationwide store checks across
                all your categories, streamed into a private space.
              </Text>
            </Li>
            <Li>
              <Text>
                Access summary analytics with reports covering pricing,
                planograms, out-of-stocks, adjacencies, and brand visibility
                with one-click PowerPoint export.
              </Text>
            </Li>
            <Li>
              <Text>
                Shelfgram Enterprise comes with full feature access and
                unlimited training and support.
              </Text>
            </Li>
          </Ul>
          <A target="_blank" href={demoLink} rel="noreferrer noopener">
            <MainCTA width="75%" text="Talk to sales" blue />
          </A>
        </Container>
        <Container style={styleSmallFont}>
          <Tilt
            perspective={500}
            glareEnable={true}
            glareMaxOpacity={0.45}
            scale={1.01}
          >
            <Graphic
              style={{
                backgroundImage: `url("${awsPrefix}web-app/sg_upgrade.png")`,
              }}
            />
          </Tilt>
          <SocialProof>
            <Trusted>Trusted by industry leaders</Trusted>
            {socialProofLogos.map((x, i) => (
              <li key={`${x}-${i}`}>
                <SocialProofLogo
                  style={{
                    backgroundColor:
                      this.props.dataUser && this.props.dataUser.dark_mode
                        ? "white"
                        : "none",
                    backgroundImage: `url("${awsPrefix}web-app/${x}.png")`,
                  }}
                />
              </li>
            ))}
          </SocialProof>
        </Container>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  dataUser: state.dataUser,
});

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) => dispatch({ type: arg.type, data: arg.data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpgradePrompt);
