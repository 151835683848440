var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
var Svg = styled.svg(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fill: ", ";\n"], ["\n  fill: ", ";\n"])), function (props) { return props.fill || props.theme.grey3; });
export var CollaborativeCollection = function (_a) {
    var fill = _a.fill, size = _a.size, style = _a.style;
    return (_jsx(Svg, { fill: fill, width: size || "21", height: size || "16", viewBox: "0 0 21 16", style: style, xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.8303 5.01184C20.8303 3.99622 19.9709 3.13684 18.9553 3.13684H11.4553L8.95526 0.636841H2.70526C1.65057 0.636841 0.830261 1.49622 0.830261 2.51184V13.7618C0.830261 14.8165 1.65057 15.6368 2.70526 15.6368H18.9553C19.9709 15.6368 20.8303 14.8165 20.8303 13.7618V5.01184ZM17.7533 10.2649C17.7533 9.61628 16.5758 9.07888 15.9272 9.07888H14.7413C14.4077 9.07888 14.1112 9.22713 13.8888 9.43097C14.6486 9.83865 15.1675 10.5799 15.2972 11.4509H17.1603C17.4753 11.4509 17.7533 11.1914 17.7533 10.8579V10.2649ZM8.68472 7.0034C8.68472 8.15232 9.61127 9.07888 10.7602 9.07888C11.8906 9.07888 12.8357 8.15232 12.8357 7.0034C12.8357 5.873 11.8906 4.92792 10.7602 4.92792C9.61127 4.92792 8.68472 5.873 8.68472 7.0034ZM14.3182 11.8215C14.3182 10.6355 13.3545 9.67187 12.1686 9.67187H12.0203C11.6312 9.85718 11.2049 9.96837 10.7602 9.96837C10.2969 9.96837 9.87071 9.85718 9.48155 9.67187H9.3333C8.14732 9.67187 7.20223 10.6355 7.20223 11.8215V12.3403C7.20223 12.8407 7.59138 13.2298 8.09172 13.2298H13.4287C13.9105 13.2298 14.3182 12.8407 14.3182 12.3403V11.8215ZM6.18186 11.4509C6.31158 10.5799 6.83045 9.83865 7.59022 9.43097C7.36785 9.22713 7.07135 9.07888 6.75632 9.07888H5.57034C4.90322 9.07888 3.6962 9.61628 3.6962 10.2649V10.8579C3.6962 11.1914 3.95563 11.4509 4.28919 11.4509H6.18186ZM16.7952 6.95282C16.7952 7.8222 16.0904 8.52698 15.221 8.52698C14.3516 8.52698 13.6468 7.8222 13.6468 6.95282C13.6468 6.08344 14.3516 5.37866 15.221 5.37866C16.0904 5.37866 16.7952 6.08344 16.7952 6.95282ZM6.22597 8.52698C7.09535 8.52698 7.80013 7.8222 7.80013 6.95282C7.80013 6.08344 7.09535 5.37866 6.22597 5.37866C5.35658 5.37866 4.65181 6.08344 4.65181 6.95282C4.65181 7.8222 5.35658 8.52698 6.22597 8.52698Z" }) }));
};
var templateObject_1;
