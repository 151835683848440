import { PureComponent } from "react";
import styled from "styled-components";
import { media } from "constants/responsive/index";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  animation: fadeIn 1s;
  padding: 1em 0.5em;
  align-items: flex-start;
  justify-content: flex-start;
  border-top: 1px solid ${(props) => props.theme.grey6};
  color: ${(props) => props.theme.grey3};
  ${media.mobile`
    flex-wrap: wrap;
  `}
`;
const Body = styled.div`
  display: flex;
  flex: 1;
  padding: 0 2em;
  flex-wrap: wrap;
  p {
    padding-right: 0.5em;
    margin: 0;
  }
  ${media.mobile`
    padding: 0;
  `}
`;
const Title = styled.h3`
  margin: 0;
  font-weight: bold;
  min-width: 5em;
  ${media.mobile`
    width: 100%;
    padding-bottom: 1em;
    text-align: center;
  `}
`;

export default class FooterRow extends PureComponent {
  render() {
    return (
      <Wrapper>
        <Title>{this.props.title}</Title>
        <Body>{this.props.body}</Body>
      </Wrapper>
    );
  }
}
